import { React, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'

const PostCard = props => {
    const post = props.post
    const [hearts, setHearts] = useState(post.hearts)

    const incrementHearts = async postid => {
        setHearts(hearts + 1)
        await fetch(
            `https://cf-smp.bahaa.me/posts/heart/${postid}`,
            {
                method: 'PUT',
            }
        )
    }

    return (
        <div>
            <Card sx={{ mx: '10%', my: '3%', minWidth: 275 }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Post by {post.username}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {post.title}
                    </Typography>
                    <Typography variant="body2">{post.content}</Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton
                        aria-label="Heart this"
                        onClick={() => {
                            incrementHearts(post.uid)
                        }}
                    >
                        <FavoriteIcon style={{color: "#CD201F"}} /> {hearts || 0}
                    </IconButton>
                </CardActions>
            </Card>
        </div>
    )
}

export default PostCard
