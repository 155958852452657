import { React, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import PostCard from './postCard'

const Posts = () => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch('https://cf-smp.bahaa.me/posts')
            const postsResp = await resp.json()
            setPosts(postsResp)
        }

        getPosts()
    }, [])

    return (
        <div>
            <h1 align="center" onClick={()=>{window.location = '/'}} style={{cursor: 'pointer'}}>Yet Another Social Media Platform</h1>
            {posts.map(post => (
                <PostCard post={post} key={post.uid} />
            ))}
            <Box sx={{ mx: 'auto', my: 5, width: 135 }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        window.location = '/create'
                    }}
                >
                    Create post
                </Button>
            </Box>
        </div>
    )
}

export default Posts
