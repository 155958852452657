import { React, useState } from 'react'
import { useForm } from 'react-hook-form'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'

const CreatePost = () => {
    const { register, handleSubmit } = useForm()
    const [alert, setAlert] = useState(false)

    const formSubmit = async data => {
        const rawResponse = await fetch('https://cf-smp.bahaa.me/posts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: data.username,
                title: data.title,
                content: data.content,
            }),
            credentials: 'include',
        })
        const content = await rawResponse.text()
        if (content === 'success') {
            window.location = '/'
        } else {
            setAlert(true)
        }
    }

    return (
        <div>
            <h1 align="center" onClick={()=>{window.location = '/'}} style={{cursor: 'pointer'}}>Yet Another Social Media Platform</h1>
            <Card sx={{ mx: '8%', my: 15 }}>
                <CardContent>
                    <Collapse in={alert}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlert(false)
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            You're not authorized to create this post.
                        </Alert>
                    </Collapse>
                    <form onSubmit={handleSubmit(formSubmit)}>
                        <FormControl fullWidth>
                            <Typography variant="h5" component="div">
                                Create Post
                            </Typography>
                            <TextField
                                id="standard-basic"
                                {...register('username')}
                                label="Username"
                                variant="standard"
                                required
                            />
                            <br />
                            <TextField
                                id="standard-basic"
                                {...register('title')}
                                label="Title"
                                variant="standard"
                                required
                            />
                            <br />
                            <TextField
                                id="standard-basic"
                                {...register('content')}
                                label="Content"
                                maxRows={50}
                                minRows={5}
                                variant="standard"
                                multiline
                                required
                                width="auto"
                            />
                            <br />
                            <Box sx={{ mx: 'auto', my: 1, px: 'auto' }}>
                                <Button variant="contained" type="submit">
                                    Submit
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default CreatePost
