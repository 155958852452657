import { Router } from '@reach/router'

import Posts from './components/posts'
import CreatePost from './components/createPost'

function App() {
    return (
        <Router>
            <Posts path="/" />
            <CreatePost path="/create" />
        </Router>
    )
}

export default App
